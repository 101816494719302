import React from "react"
import { Box, Image, Text } from "@chakra-ui/react"
import { useRouteLoaderData } from "react-router-dom"
import env from "../lib/env"
import Logo from "../images/horizontal-light.svg"
import AccountLayout from "@/components/AccountLayout"
import GradientBox from "@/components/GradientBox"

const publishableKey =
  process.env.BLINDINSIGHT_STRIPE_PUBLISHABLE_KEY ||
  env.BLINDINSIGHT_STRIPE_PUBLISHABLE_KEY
const pricingTableId =
  process.env.BLINDINSIGHT_STRIPE_PRICING_TABLE_ID ||
  env.BLINDINSIGHT_STRIPE_PRICING_TABLE_ID

export default function ChoosePlanView() {
  const user = useRouteLoaderData("auth")
  return (
    <AccountLayout>
      <GradientBox>
        <Image src={Logo} width="20vw" alt="Blind Insight" />
        <Text fontSize="3vh" color="white" opacity="90%">
          Please choose a plan
        </Text>
        <div />
      </GradientBox>
      <Box
        width="55vw"
        bg="white"
        mb={"auto"}
        justifyContent="space-between"
        pt="5vh"
        alignItems="center"
        borderRadius="2em"
      >
        <stripe-pricing-table
          pricing-table-id={pricingTableId}
          publishable-key={publishableKey}
          customer-email={user.email}
          client-reference-id={user.id}
        />
      </Box>
    </AccountLayout>
  )
}

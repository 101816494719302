import React from "react"
import { useSearchParams } from "react-router-dom"
import { Api } from "../lib/api"
import { Box, Image, Text } from "@chakra-ui/react"
import GradientBox from "./GradientBox"
import Logo from "../images/logoverticallight-1.png"
import Link from "./Link"

// The URL to redirect to after activation succeeds.
// Previously this redirected to the Intercom tour but now we're just going to
// redirect to the choose plan page, the pricing table will redirect to the Intercom tour.
const POST_ACTIVATION_REDIRECT_URL = "/choose-plan"

export default function AccountsActivate(props) {
  const [searchParams] = useSearchParams()
  const { setActivated, setError } = props
  const api = new Api()

  const user_id = searchParams.get("user_id")
  const timestamp = searchParams.get("timestamp")
  const signature = searchParams.get("signature")

  React.useEffect(() => {
    if (!user_id || !timestamp || !signature) {
      setError("Invalid activation link. Please request a new one.")
      return
    }

    const activate = async () => {
      // const resp = await api.OrganizationInvitationAccept(orgId, invId)
      console.log("Sending activation request")
      const resp = await api.accountsVerifyRegistration(
        user_id,
        timestamp,
        signature,
      )
      return resp
    }

    activate()
      .then((resp) => {
        console.log("Activation success")
        setActivated(getSuccessMessage(resp))
      })
      .catch((error) => error.json().then((r) => setError(r.detail)))
  }, [user_id, timestamp, signature])

  const getSuccessMessage = (resp) => {
    console.log("getSuccessMessage resp =", resp)
    return (
      <Box>
        <p>{resp.detail} </p>
        <p>
          <Text>
            <Link to={POST_ACTIVATION_REDIRECT_URL}>Click here to proceed</Link>
          </Text>
        </p>
      </Box>
    )
  }

  return (
    <GradientBox>
      <Image src={Logo} alt="Blind Insight Logo" />
    </GradientBox>
  )
}
